import { FETCH_ALL_PAGE_SIZE } from '../../utils/utils';
import { getRequest, postRequest, putRequest } from '../utils/axios.utils';

export const getAllMachines = (values) => {
  return postRequest(`machines`, {
    ...values,
    size: FETCH_ALL_PAGE_SIZE,
    page: 0,
  });
};

export const AssociateTrackerToMachine = (values) => {
  return postRequest(`machines/tracker/set`, {
    ...values,
  });
};

export const postUnpairTracker = (values) => {
  return postRequest(`machines/tracker/unset`, {
    ...values,
  });
};

export const getUnassignedAllMachines = (values) => {
  return postRequest(`machines/unassigned`, values);
};

export const getMachineById = (id) => {
  return getRequest(`machines/${id}`);
};

export const getMachinesByGrpId = (id) => {
  return getRequest(`machines/grp/${id}`);
};

export const getTrackerFromMachineId = (id) => {
  return getRequest(`machines/${id}/tracker`);
};

export const getAggMachines = (values) => {
  return postRequest(`machines/agg`, values);
};

export const getAllMachinesGpsPosition = (values) => {
  return postRequest(`machines/all/gps/position`, {
    ...values,
    size: 100000,
    page: 0,
  });
};

export const getAllMachinesGpsGoobies = (values) => {
  return postRequest(`machines/all/gps/goobies`, {
    ...values,
    size: 100000,
    page: 0,
  });
};

export const getAllMachinesGpsGoobiesGeoJson = (values) => {
  return postRequest(`machines/all/gps/goobies/geojson`, {
    ...values,
  });
};

export const getMachineUsageForAnInterval = (
  id,
  customerNumber,
  startDate,
  endDate
) => {
  return postRequest(`machines/information/metrics/utilisation/${id}`, {
    customerNumber,
    startDate,
    endDate,
  });
};

export const getMachinesDynamicFilter = ({
  status,
  jobsiteId,
  contractId,
  agencyId,
}) => {
  return postRequest(`machines/dynamic-filter`, {
    status,
    jobsiteId,
    contractId,
    agencyId,
  });
};

export const getMachineUsageAlerts = (
  id,
  customerNumber,
  page = 0,
  size = 5
) => {
  return postRequest(`machines/information/metrics/utilisation/alerts/${id}`, {
    customerNumber,
    page,
    size,
  });
};

export const getMachineClients = (id) => {
  return getRequest(`machines/clients/${id}`);
};

export const getAgencyMachines = (id) => {
  return postRequest(`machines/agency/all`, { agencyId: id, size: 5000 }); // 5000 is set to retrieve all machine at once
};

// Security
export const getMachineSecurity = (id, customerNumber, startDate, endDate) => {
  return postRequest(`machines/information/metrics/security/${id}`, {
    customerNumber,
    startDate,
    endDate,
  });
};

export const getMachineSecurityAlerts = (
  id,
  customerNumber,
  page = 0,
  size = 5,
  startDate,
  endDate
) => {
  return postRequest(`machines/information/metrics/security/alerts/${id}`, {
    customerNumber,
    page,
    size,
    startDate,
    endDate,
  }).catch((e) => {
    console.error('Error getting machine security alerts:', e);
    return [];
  });
};

export const getCockpitTrackers = () => {
  return getRequest(`machines/cockpit`);
};

export const getSyncCockpitDetails = (origin) => {
  return getRequest(`machines/cockpit-details/${origin}/sync`);
};

export const getAsyncCockpitDetails = (origin) => {
  return getRequest(`machines/cockpit-details/${origin}/async`);
};

export const getMachineHistoryData = (id, data) => {
  return postRequest(`geoloc/history/${id}`, data);
};

export const getMachineJourneyCsv = (id, data) => {
  return postRequest(`geoloc/journey/export/${id}`, data);
};

export const updateMachineMapData = (data) => {
  return putRequest(`machines/update`, data);
};

export const saveRadiusCenter = (data) => {
  return postRequest(`geofencing/save`, data);
};

export const getRadiusCenter = (id) => {
  return getRequest(`geofencing/${id}`);
};

export const getMachineAvailableProviders = (id) => {
  return getRequest(`geoloc/last-geoloc-info-for-providers/${id}`);
};
