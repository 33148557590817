import { useState } from 'react';
import { useUserContext } from '../contexts/UserContext/UserContext';
import DropdownDatepickerField from './DropdownDatepickerField';
import Form from '../../lib/apptheme/components/Form';

const DateRangeForm = ({ handleSubmit, initialValues }) => {
  const user = useUserContext();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <Form
      initialValues={initialValues}
      onSubmit={(values, actions) => {
        toggle();
        handleSubmit(values.dateRange);
        actions.setSubmitting(false);
      }}
      hideSubmitButton
    >
      <DropdownDatepickerField
        name="dateRange"
        dropdownOpen={dropdownOpen}
        toggle={toggle}
        lang={user ? user.lang : null}
      />
    </Form>
  );
};

export default DateRangeForm;
