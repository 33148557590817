import { FETCH_ALL_PAGE_SIZE } from '../../utils/utils';
import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
  patchRequest,
} from '../utils/axios.utils';

export const getSiteById = (id) => {
  return getRequest(`machine-group/${id}`);
};

export const getAllSites = () => {
  return getRequest(`machine-group`);
};

export const getAllJobSite = () => {
  return postRequest(`sites`, { size: FETCH_ALL_PAGE_SIZE });
};

export const getJobSiteById = (id) => {
  return getRequest(`sites/${id}`);
};

export const getJobSiteMachines = (id) => {
  return postRequest(`machines/site/all`, { jobSiteId: id });
};

const getIds = (arr) => {
  return arr.map((i) => i.id);
};

export const getAgencyJobSites = (id) => {
  return getRequest(`sites/agency/${id}`);
};

export const getJobSitesByContractId = (id) => {
  return getRequest(`sites/contract/${id}`);
};

export const createSite = (values) => {
  const { name, description, enabled, users, machines } = values;
  const userz = getIds(users);
  const machinez = getIds(machines);

  const body = {
    name,
    description,
    enabled,
    users: userz,
    machines: machinez,
  };
  return postRequest(`machine-group/`, body);
};

export const updateSite = (id, values) => {
  const { name, description, enabled, users, machines } = values;
  const userz = getIds(users);
  const machinez = getIds(machines);

  const body = {
    id,
    name,
    description,
    enabled,
    users: [...new Set(userz)],
    machines: [...new Set(machinez)],
  };
  return putRequest(`machine-group/${id}`, body);
};

export const deleteSite = (id) => {
  return deleteRequest(`machine-group/${id}`);
};

export const updateJobSiteZone = (id, zone) => {
  return patchRequest(`sites/${id}`, zone);
};

export const updateJobSitePosition = (id, lat, lon, isInternalAppMode) => {
  const url = isInternalAppMode
    ? `sites/${id}/position`
    : `sites/${id}/position/loxConnect`;

  return putRequest(url, { lat, lon });
};

export const getJobSitesWithSecurityByClient = () => {
  return getRequest('sites/metrics/security/sessions/client');
};

export const getAllJobSitesSecurityByClient = (startDate, endDate) => {
  return postRequest('sites/metrics/security/client', {
    startDate,
    endDate,
  });
};

export const getAllJobSitesSecurityByAgence = (
  agencyId,
  startDate,
  endDate
) => {
  return postRequest(`sites/metrics/security/${agencyId}`, {
    startDate,
    endDate,
  });
};

export const getJobSiteSecurity = ({
  jobSiteId,
  machineId,
  startDate,
  endDate,
}) => {
  return postRequest(`sites/machine/metrics/security/${jobSiteId}`, {
    machineId,
    startDate,
    endDate,
  });
};

export const getJobSiteAlertsSecurity = ({
  jobSiteId,
  machineId,
  startDate,
  endDate,
  page = 0,
  size = 5,
}) => {
  return postRequest(`sites/machine/metrics/security/alerts/${jobSiteId}`, {
    startDate,
    endDate,
    machineId,
    page,
    size,
  }).catch((e) => {
    console.error('Error getting job site security alerts:', e);
    return [];
  });
};

export const getAllJobSitesAlertsSecurityByClient = ({
  startDate,
  endDate,
  page = 0,
  size = 5,
}) => {
  return postRequest('sites/metrics/security/alerts/client', {
    startDate,
    endDate,
    page,
    size,
  }).catch((e) => {
    console.error('Error getting all job sites security alerts:', e);
    return [];
  });
};
