import { t } from '@lingui/macro';
import moment from 'moment';
import 'moment/locale/fr';
import DateRangePicker from 'react-bootstrap-daterangepicker';

import 'bootstrap-daterangepicker/daterangepicker.css';

import Field from '../../lib/apptheme/components/Field';

const DropdownDatepickerComponent = ({
  field,
  setFieldValue,
  submitForm,
  lang,
}) => {
  const handleCallback = (state) => {
    setFieldValue(field.name, state);
    submitForm();
  };

  let label;
  let ranges;
  if (lang === 'FR') {
    const s = moment(field.value.start, 'DD MMMM YYYY');
    const e = moment(field.value.end, 'DD MMMM YYYY');
    label = `${s.locale('fr').format('DD MMMM YYYY')} - ${e
      .locale('fr')
      .format('DD MMMM YYYY')}`;
    ranges = {
      "Aujourd'hui": [
        moment().locale('fr').startOf('day').toDate(),
        moment().locale('fr').endOf('day').toDate(),
      ],
      Hier: [
        moment().locale('fr').subtract(1, 'days').toDate(),
        moment().locale('fr').subtract(1, 'days').toDate(),
      ],
      'Cette semaine': [
        moment().locale('fr').startOf('week').toDate(),
        moment().locale('fr').endOf('week').toDate(),
      ],
      'Semaine dernière': [
        moment().locale('fr').subtract(7, 'days').startOf('week').toDate(),
        moment().locale('fr').subtract(7, 'days').endOf('week').toDate(),
      ],
      'Ce mois': [
        moment().locale('fr').startOf('month').toDate(),
        moment().locale('fr').endOf('month').toDate(),
      ],
      'Mois dernier': [
        moment().locale('fr').subtract(1, 'month').startOf('month').toDate(),
        moment().locale('fr').subtract(1, 'month').endOf('month').toDate(),
      ],
      '3 derniers mois': [
        moment().locale('fr').subtract(3, 'month').startOf('month').toDate(),
        moment().locale('fr').subtract(1, 'month').endOf('month').toDate(),
      ],
    };
  } else {
    const s = moment(field.value.start, 'DD MMMM YYYY');
    const e = moment(field.value.end, 'DD MMMM YYYY');
    label = `${s.locale('en').format('DD MMMM YYYY')} - ${e
      .locale('en')
      .format('DD MMMM YYYY')}`;
    ranges = {
      Today: [
        moment().locale('en').startOf('day').toDate(),
        moment().locale('en').endOf('day').toDate(),
      ],
      Yesterday: [
        moment().locale('en').subtract(1, 'days').toDate(),
        moment().locale('en').subtract(1, 'days').toDate(),
      ],
      'This week': [
        moment().locale('en').startOf('week').toDate(),
        moment().locale('en').endOf('week').toDate(),
      ],
      'Last week': [
        moment().locale('en').subtract(7, 'days').startOf('week').toDate(),
        moment().locale('en').subtract(7, 'days').endOf('week').toDate(),
      ],
      'This month': [
        moment().locale('en').startOf('month').toDate(),
        moment().locale('en').endOf('month').toDate(),
      ],
      'Last month': [
        moment().locale('en').subtract(1, 'month').startOf('month').toDate(),
        moment().locale('en').subtract(1, 'month').endOf('month').toDate(),
      ],
      'Last 3 months': [
        moment().locale('en').subtract(3, 'month').startOf('month').toDate(),
        moment().locale('en').subtract(1, 'month').endOf('month').toDate(),
      ],
    };
  }

  return (
    <DateRangePicker
      onApply={(event, date) => {
        const newState = {
          // eslint-disable-next-line no-underscore-dangle
          start: moment(new Date(date.startDate._d)).add(1, 'hour'),
          // eslint-disable-next-line no-underscore-dangle
          end: moment(new Date(date.endDate._d)).add(1, 'hour'),
        };
        handleCallback(newState);
      }}
      initialSettings={{
        startDate: field.value.start,
        endDate: field.value.end,
        locale: {
          customRangeLabel: t`Personnalisée`,
          applyLabel: t`Appliquer`,
          cancelLabel: t`Annuler`,
        },
        ranges,
      }}
    >
      <button type="button" className="btn btn-secondary">
        <span>{label}</span>
      </button>
    </DateRangePicker>
  );
};

const DropdownDatepickerField = ({
  name,
  props,
  setFieldValue,
  submitForm,
  dropdownOpen,
  toggle,
  lang,
}) => (
  <Field
    {...props}
    name={name}
    lang={lang}
    setFieldValue={setFieldValue}
    submitForm={submitForm}
    toggle={toggle}
    dropdownOpen={dropdownOpen}
    component={DropdownDatepickerComponent}
  />
);

export default DropdownDatepickerField;
