import { postRequest } from '../utils/axios.utils';

export const postCreateNotificationsParam = (values) => {
  return postRequest('alert-params/create', {
    ...values,
    active: false,
    metricType: 'SECURITY',
  });
};

export const getUserNotifications = ({ page = 0, size = 5 }) => {
  return postRequest('data/alerts/user-notifications', {
    page,
    size,
  }).catch((e) => {
    console.error('Error getting all notifications:', e);
    return [];
  });
};
