import axios from 'axios';
import { headers } from './axios.constant';

const instance = axios.create({
  withCredentials: true,
  baseURL: `${process.env.REACT_APP_BACKEND_URL}/api`,
  headers,
});

const addAuthorizationToken = (token: string) => {
  instance.defaults.headers.common.Authorization = `Bearer ${token}`;
};

const removeAuthorizationToken = () => {
  instance.defaults.headers.common.Authorization = '';
};

export { instance, addAuthorizationToken, removeAuthorizationToken };
